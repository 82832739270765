import updatesUpdatedAt from '@js/nal/vue-mixins/updatesUpdatedAt.js';

export default {
    mixins: [
        updatesUpdatedAt,
    ],
    computed: {
        _model: {
            get() {
                return this._rootParent && this._rootParent.model;
            },
            set(model) {
                const nalObj = this.nal[this._dataType];
                if (!nalObj) {
                    return;
                }
                const index = _(nalObj.data).findIndex(['id', model.id]);
                if (~index) {
                    this.nal[this._dataType].data.splice(index, 1, model);
                } else {
                    nalObj.data.unshift(model);
                    if (_(nalObj).has('meta.total')) {
                        nalObj.meta.total++;
                    }
                    if (_(nalObj).has('meta.active_total')) {
                        this.nal[this._dataType].meta.active_total++;
                    }
                    if (_(nalObj).has('meta.unfilteredTotal')) {
                        this.nal[this._dataType].meta.unfilteredTotal++;
                    }
                }
            },
        },
        _dataType() {
            return this._rootParent && this._rootParent.dataType;
        },
        _rootParent() {
            let parent = this;
            while (!this._hasModel(parent) && parent.$parent) {
                parent = parent.$parent;
            }
            if (this._hasModel(parent)) {
                return parent;
            }
            return null;
        },
        canEdit() {
            const type = this._dataType === 'targetCompanies' ? 'companies' : this._dataType;
            return this.auth('edit', type, this._model);
        },
        canEditOrCreate() {
            return this.canEdit || this.canCreate;
        },
        canCreate() {
            return window.auth('create', this._dataType || this.pageType);
        },
        canEditOnlySearch() {
            return _.get(this.nal, 'user') && this.canEdit;
        },
    },
    methods: {
        _hasModel(instance) {
            return typeof instance.$data.model !== 'undefined'
                || typeof instance.$props.model !== 'undefined';
        },
        _url(url = '', type, vue = false) {
            let resource = type || this._dataType;
            let fullUrl = (vue ? '' : '/api') + `${this._model && this._dataType ? '/' + resource : ''}`;
            if (this._dataType && this._model && this._model.id) {
                fullUrl = fullUrl + `/${this._model.id}`;
            }
            if (url) {
                fullUrl = fullUrl + `/${url}`;
            }
            return fullUrl;
        },
        _vueUrl(url, type) {
            return this._url(url, type, true);
        },
        auth(action, type, obj) {
            if (obj || type) {
                return window.authorize(action, type, obj, { model: this._model, type: this._dataType });
            }
            let modelType = this._dataType === 'leads' ? 'contacts' : this._dataType;
            return !_(this._model).get('id') || window.authorize(action, modelType, this._model);
        },
        _isEmptyField(field) {
            return _.isEmpty(field) || _.isEmpty(field[0]);
        },
    },
};
